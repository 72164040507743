<template>
  <div class="noClick">
    <div class="flix-form-group">
      <a
        href="#"
        class="flix-html-a flix-text-grey"
        @click.prevent="preview = !preview"
        ><icon class="icon" id="eye" /> {{ $t("design.preview") }}</a
      >
    </div>
    <div v-if="preview" class="preview" :style="'border-color:' + getColor()">
      <div class="title" :style="'color:' + getColor()">{{ getTitle() }}</div>
      <div class="line"></div>
      <div :style="'background-color:' + getColor()" class="button">
        {{ $store.state.business.unsaved.startBtn }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      preview: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getTitle() {
      if (this.$store.state.business.unsaved.title) {
        return this.$store.state.business.unsaved.title;
      } else {
        return this.$t("design.name.title");
      }
    },
    getColor() {
      return this.$store.state.business.unsaved.styling.accent;
    }
  }
};
</script>
<style lang="sass" scoped>
.noClick
  cursor: not-allowed
  .preview
    width: 100%
    max-width: 300px
    border: 1px solid black
    font-family: Arial, sans-serif
    display: flex
    align-items: center
    flex-direction: column
    padding: 10px
    font-size: 12pt
    .title
      font-weight: bold
    .line
      width: 100%
      height: 1px
      display: block
      border-bottom: 1px dotted #ccc
      background-color: transparent
      margin: 10px 0
    .button
      padding: 10px
      color: white
</style>
